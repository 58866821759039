import { IconLocation, IconMap2 } from '@tabler/icons';
import { Link } from 'gatsby';
import React from 'react';

export default function KirchenBox({
    data: { id, ort, stadtteil, name, strasse, plz, image_url },
}) {
    return (
        <Link
            to={`?kid=${id}`}
            className='flex flex-col-reverse sm:flex-row overflow-hidden cursor-pointer bg-white rounded-lg shadow-navbar  transition duration-200 ease-in-out transform hover:scale-102'
        >
            <div className='sm:w-full p-4 align-self-stretch flex-grow-0 text-gray-900'>
                <span className='text-yellow-500 leading-none text-sm '>
                    {ort} - {stadtteil}
                </span>

                <h3 className='text-gray-900 mt-0 mb-3 leading-tight text-xl'>
                    {name}
                </h3>
                <div className='flex '>
                    <IconMap2 className='h-5 w-5 mr-4 mt-1 stroke-2' />
                    <p className='mb-0'>
                        {strasse}
                        <br /> {plz} {ort}
                    </p>
                </div>
            </div>
            {image_url && (
                <div
                    className='sm:w-6/12 sm:h-auto w-full h-40  bg-center bg-cover'
                    style={{
                        backgroundImage: `url(${image_url})`,
                    }}
                ></div>
            )}
        </Link>
    );
}
