import { IconCircleDotted, IconLoader } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import KirchenBox from '../components/KirchenBox';
import KirchenBoxFull from '../components/KirchenBoxFull';
import SEO from '../components/SEO';

import * as queryString from 'query-string';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Link } from 'gatsby';

export default function Gebetstag({ location }) {
    const [kirchen, setKirchen] = useState([]);
    const { kid } = queryString.parse(location.search);

    useEffect(() => {
        fetch('http://localhost:3000/kirchen')
            .then((response) => response.json())
            .then((data) => {
                setKirchen(data);
                console.log(data);
            });
    }, []);

    return (
        <Container>
            <SEO title='Weltgebetstag' />
            <div className='d-flex flex-column'>
                <h1>Weltgebetstag</h1>

                {kid ? (
                    <>
                        {kirchen.length == 0 && (
                            <IconLoader
                                className='text-center mx-auto justify-self-center my-10 animate-spin col-span-3'
                                size={40}
                            />
                        )}
                        {kirchen.length != 0 && (
                            <KirchenBoxFull
                                data={kirchen.find(
                                    (kirche) => kirche.id === kid
                                )}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <p>
                            Herzlichen laden wir Sie ein am 24./25. April 2021,
                            dem Weltgebetstag für geistliche Berufungen, in
                            vielen Kirchen unseres Erzbistums{' '}
                            <strong>
                                24h für eine geistliche Erneuerung in unseren
                                Gemeinden und geistliche Berufungen zu beten.
                            </strong>{' '}
                            Unter dem Leitwort{' '}
                            <em>
                                „Der, der Euch beruft, ist treu!“ (1 Thess 5,24)
                            </em>{' '}
                            werden wir uns von 18:00 bis 18:00 vor dem
                            Eucharistischen Herrn versammeln und unsere Bitten
                            und Nöte vor IHN tragen. Gott, der jeden einzelnen
                            von uns zu sich berufen hat, ist treu. Seine
                            Selbstbeschreibung{' '}
                            <em>„Ich bin, der ich bin da“ (Ex 3,14)</em> hat
                            auch in diesen Zeiten Bestand. Er, der die Kirche
                            gegründet hat, bleibt ihr und den Menschen treu. Das
                            ist seine Zusage.
                        </p>
                        <p>
                            Durch unsere gemeinsame Teilnahme am Weltgebetstag
                            für Berufungen wollen wir ein Zeichen des Aufbruchs
                            und der Erneuerung setzen. Wir wollen zeigen, dass
                            wir uns nach jeder einzelnen Berufung sehnen. Gerade
                            jetzt. Wir wollen zeigen, dass wir jene, die dem Ruf
                            Gottes gefolgt sind und den Menschen dienen,
                            unterstützen und ihnen vertrauen. Wir wollen zeigen,
                            dass wir uns auf SEINE Treue verlassen.{' '}
                            <strong>
                                Bitte helfen Sie durch Ihre Teilnahme am
                                Weltgebetstag für geistliche Berufungen, ein
                                Zeichen des Aufbruchs und der Erneuerung zu
                                setzen!
                            </strong>
                        </p>
                        <h2 className='text-xl text-center font-medium mt-10'>
                            Folgende Kirchen in unserem Erzbistum nehmen bisher
                            am 24h Gebet für geistliche Berufungen teil:
                        </h2>
                        {kirchen.length == 0 && (
                            <IconLoader
                                className='text-center mx-auto justify-self-center my-10 animate-spin col-span-3'
                                size={40}
                            />
                        )}

                        {kirchen.length != 0 && (
                            <>
                                {typeof window !== 'undefined' && (
                                    <MapContainer
                                        //@ts-ignore
                                        center={[51.0, 6.9]}
                                        zoom={8}
                                        scrollWheelZoom={false}
                                        className='h-96 rounded-lg shadow-lg my-10 mx-auto md:w-3/4'
                                    >
                                        <TileLayer
                                            //@ts-ignore
                                            attribution='&copy; <a  href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                            url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                        />
                                        {kirchen &&
                                            kirchen.map((kirche) => (
                                                <Marker
                                                    position={[
                                                        kirche.latitude,
                                                        kirche.longitude,
                                                    ]}
                                                >
                                                    <Popup>
                                                        <strong>
                                                            {kirche.name}
                                                        </strong>
                                                        <br />
                                                        {kirche.ort}
                                                        <br />
                                                        <Link
                                                            to={
                                                                '/gebetstag?kid=' +
                                                                kirche.id
                                                            }
                                                        >
                                                            Zur Anmeldung
                                                        </Link>
                                                    </Popup>
                                                </Marker>
                                            ))}
                                    </MapContainer>
                                )}
                                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                                    {kirchen.map((kirche) => (
                                        <KirchenBox
                                            data={kirche}
                                            key={kirche.id}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </Container>
    );
}
