import { IconLoader, IconMap2, IconX } from '@tabler/icons';
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

const RegistrationForm = ({ data, zeit, cancel, rerender }) => {
    const [sending, setSending] = useState(false);
    const [formdata, setFormdata] = useState({
        vorname: 'Max',
        nachname: 'Mustermann',
        email: 'markus.marcinek@outlook.de',
        plz: '',
        strasse: '',
        ort: '',
        telefon: '',
        checkbox: false,
        kid: data.id,
        zeit,
        submitbutton: 'Senden',
    });
    const [response, setResponse] = useState({
        message: '',
        status: '',
    });
    const formRef = useRef();

    const sendForm = async (event) => {
        event.preventDefault();
        setSending(true);
        //@ts-ignore
        const valid = formRef.current.reportValidity();
        if (valid) {
            const rawResponse = await fetch('http://localhost:3000/anmeldung', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formdata),
            });
            const content = await rawResponse.json();
            setSending(false);
            if (content.errors) {
                setResponse({
                    message: content.errors[0]?.msg,
                    status: 'error',
                });
            } else {
                setFormdata({
                    vorname: '',
                    nachname: '',
                    email: '',
                    plz: '',
                    strasse: '',
                    ort: '',
                    telefon: '',
                    checkbox: false,
                    kid: data.id,
                    zeit,
                    submitbutton: 'Senden',
                });
                setResponse({
                    message: 'Ihre Anmeldung wurde erfolgreich verschickt.',
                    status: 'sent',
                });
                rerender();
            }
        }
        setSending(false);
    };

    const handleInputChange = (event) => {
        const { type, checked, value, name } = event.target;
        setFormdata({
            ...formdata,
            [name]: type === 'checkbox' ? checked : value,
        });
        console.log(formdata);
    };

    return (
        <>
            <div
                className='fixed z-10 inset-0 overflow-y-auto '
                aria-labelledby='modal-title'
                role='dialog'
                aria-modal='true'
            >
                <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                    <div
                        className='fixed inset-0 bg-gray-600 opacity-75'
                        aria-hidden='true'
                        onClick={() => cancel()}
                    ></div>

                    <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
                        <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                            <div className='sm:flex sm:items-start'>
                                <form ref={formRef}>
                                    <h3>Anmeldung zum Gebetstag</h3>
                                    <button
                                        className='absolute top-3 right-3 rounded-full bg-gray-50 p-2 focus:outline-none '
                                        onClick={() => cancel()}
                                    >
                                        <IconX size={15} strokeWidth={3} />
                                    </button>
                                    <span className='block mb-2'>
                                        <strong>Ort: </strong>
                                        {data.name}({data.ort})
                                    </span>
                                    <span className='block mb-2'>
                                        <strong>Uhrzeit: </strong>
                                        {new Date(zeit).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        }) + ' Uhr'}
                                    </span>
                                    <hr className='my-4' />

                                    <div className='form-group'>
                                        <label>Vorname *</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='vorname'
                                            value={formdata.vorname}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            required
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Nachname *</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='nachname'
                                            value={formdata.nachname}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                            required
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>E-Mail *</label>
                                        <input
                                            type='email'
                                            className='form-control'
                                            name='email'
                                            required
                                            value={formdata.email}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Straße &amp; Hausnummer</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='strasse'
                                            value={formdata.strasse}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Postleitzahl</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='plz'
                                            minLength={5}
                                            maxLength={5}
                                            value={formdata.plz}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Stadt</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='ort'
                                            value={formdata.ort}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <label>Telefonnummer</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='telefon'
                                            value={formdata.telefon}
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>

                                    <div className='form-group'>
                                        <div className='form-check flex'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                name='checkbox'
                                                checked={formdata.checkbox}
                                                onChange={(e) =>
                                                    handleInputChange(e)
                                                }
                                            />
                                            <label className='form-check-label'>
                                                Ich möchte über die Arbeit der
                                                rogamus-Stiftung informiert
                                                werden.
                                            </label>
                                        </div>
                                    </div>

                                    <small>
                                        Felder markiert mit * sind
                                        Pflichtfelder.
                                    </small>

                                    {response.message && (
                                        <p
                                            className={`${
                                                response.status === 'sent'
                                                    ? 'text-green-700 bg-green-100 border-green-700'
                                                    : 'text-red-700 bg-red-100 border-red-700'
                                            } shadow-md font-medium border px-5 py-2 rounded-lg my-2`}
                                        >
                                            {response.message}
                                        </p>
                                    )}
                                </form>
                            </div>
                        </div>
                        <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                            <button
                                type='button'
                                className='sm:ml-5 justify-center btn w-full sm:w-auto'
                                onClick={(e) => {
                                    sendForm(e);
                                }}
                                disabled={sending}
                            >
                                {sending && (
                                    <IconLoader
                                        className='mr-2 mb-1 inline animate-spin '
                                        size={20}
                                    />
                                )}
                                Anmeldung abschicken
                            </button>
                            <button
                                type='button'
                                className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                onClick={() => cancel()}
                            >
                                Abbrechen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default function KirchenBoxFull({
    data: {
        id,
        ort,
        stadtteil,
        name,
        strasse,
        plz,
        image_url,
        latitude,
        longitude,
    },
}) {
    const [data, setData] = useState({ gebetszeiten: [] });
    const [showRegistration, setShowRegistration] = useState(false);
    const [selectedTime, setSelectedTime] = useState('');
    const [loading, setLoading] = useState(true);

    const getData = () => {
        fetch(`http://localhost:3000/kirchendata-anmeldungen?kid=${id}`)
            .then((response) => {
                return response.json();
            })
            .then((jData) => {
                setData(jData);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {showRegistration && (
                <RegistrationForm
                    data={{ id, ort, stadtteil, name, strasse, plz, image_url }}
                    zeit={selectedTime}
                    cancel={() => setShowRegistration(false)}
                    rerender={() => getData()}
                />
            )}
            {data && (
                <div className='flex flex-col overflow-hidden bg-white rounded-lg shadow-navbar '>
                    <div className='flex sm:flex-row flex-col'>
                        <div className='sm:w-5/12 p-4 align-self-stretch flex-grow-0 text-gray-900'>
                            <span className='text-yellow-500 leading-none text-sm '>
                                {ort} - {stadtteil}
                            </span>
                            <h3 className='text-gray-900 mt-0 mb-3 leading-tight'>
                                {name}
                            </h3>
                            <div className='flex'>
                                <IconMap2 className='h-5 w-5 mr-4 mt-1 stroke-2' />
                                <p className='mb-0'>
                                    {strasse}
                                    <br /> {plz} {ort}
                                </p>
                            </div>
                        </div>

                        {typeof window !== 'undefined' && (
                            <MapContainer
                                //@ts-ignore
                                center={[latitude, longitude]}
                                zoom={15}
                                scrollWheelZoom={false}
                                className='w-full h-48 sm:w-6/12  z-0'
                            >
                                <TileLayer
                                    //@ts-ignore
                                    attribution='&copy; <a  href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                    url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                />
                                <Marker position={[latitude, longitude]}>
                                    Test
                                </Marker>
                            </MapContainer>
                        )}

                        <div
                            className='w-full h-48 sm:w-2/12 sm:h-auto bg-center bg-cover'
                            style={{
                                backgroundImage: `url(${image_url})`,
                            }}
                        ></div>
                    </div>

                    <hr />
                    <div className='block'>
                        {loading && (
                            <IconLoader
                                className='text-center mx-auto justify-self-center my-10 animate-spin col-span-3'
                                size={40}
                            />
                        )}
                        {data.gebetszeiten &&
                            data.gebetszeiten.map((zeit, index) => {
                                if (zeit.faellt_aus) return;
                                return (
                                    <div
                                        className='flex-row odd:bg-gray-50'
                                        key={index}
                                    >
                                        <div className='p-4 flex-col'>
                                            <h4 className='block mb-0 font-medium '>
                                                {new Date(
                                                    zeit.zeit
                                                ).toLocaleTimeString([], {
                                                    weekday: 'long',
                                                    day: '2-digit',
                                                    month: 'long',
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                }) + ' Uhr'}
                                            </h4>
                                            <span className='block text-tiny font-normal text-gray-800'>
                                                {zeit.info}
                                            </span>
                                            {zeit.namen.length < 12 && (
                                                <button
                                                    className='text-center block text-baseline text-yellow-500 hover:text-yellow-700 '
                                                    onClick={() => {
                                                        setSelectedTime(
                                                            zeit.zeit
                                                        );
                                                        setShowRegistration(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Anmelden
                                                </button>
                                            )}

                                            <div className='grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4  lg:grid-cols-6  gap-2 mt-2'>
                                                {zeit.namen.map(
                                                    (name, index) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                className='rounded-full bg-gray-200 py-2 px-4 truncate'
                                                            >
                                                                {name}
                                                            </span>
                                                        );
                                                    }
                                                )}

                                                {zeit.namen.length < 12 && (
                                                    <span className='text-gray-600 rounded-full border-gray-300 border py-2 px-4 italic font-light text-sm'>
                                                        Noch{' '}
                                                        {12 - zeit.namen.length}{' '}
                                                        {zeit.namen.length == 11
                                                            ? 'Platz'
                                                            : 'Plätze'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </>
    );
}
